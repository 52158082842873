import React from 'react';
import './HeroSection.css';
import {Link} from "react-router-dom";
import {HiOutlineLink} from "react-icons/hi";
import {FaEnvelope, FaGithub, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa"; // Import the CSS file for styling

const HeroSection = () => {
    const skills = ["Java","SQL" ,"Python","Play","Spring boot","React","MongoDB","RabbitMQ", "Kafka","Redis", "Memcached", "AWS Ec2", "AWS S3","AWS Lambda","AWS SQS", "Angular.js","Docker","Git","Jira","Agile","GraphQL","Django", "Azure", "Flask","FastAPI","Hadoop","REST", "JavaScript","Material UI"];
    const exps = [
        {
            "startDate" : "Jan 2022",
            "endDate" :  "Mar 2023",
            "role" : "Software Development Engineer Intern",
            "company" : "SmartBot360",
            "description" : "Working on chatBot solutions, Collaborated closely with the product team to enhance a chat management system, achieving a 550% boost in service performance by optimizing backend processes with Spring and SQL. Led the development of Spring-based RESTful services for chatbot management on AWS ECS, ensuring efficient data handling. Designed and implemented a React-based SMS follow-up interface, significantly improving patient engagement. Additionally, developed a comprehensive React dashboard for real-time session tracking and notifications, facilitating seamless user interactions.",
            "skills" : ["Java","Flask","React", "Angular.js", "JavaScript", "REST", "AWS ECS","SQL"]
        },
        {
            "startDate" : "Apr 2020",
            "endDate" :  "Sep 2020",
            "role" : "Software Development Engineer Intern",
            "company" : "Sonata Software LTD",
            "description" : "Led the creation and scaling of RESTful APIs for tour itinerary management, utilizing Java and deploying on AWS EC2. Crafted a dynamic React front-end for the itinerary system, achieving seamless integration with Spring-based APIs. Boosted profile service efficiency by 200% via Memcached caching and SQL optimization. Implemented CI/CD pipelines with GitHub Actions, enhancing engineering productivity by 30%.",
            "skills" : ["Java", "React.js", "JavaScript","AWS Ec2", "Spring Boot", "SQl", "CI/CD"]
        },
        {
            "startDate" : "Apr 2019",
            "endDate" :  "Jun 2019",
            "role" : "Software Development Engineer Intern",
            "company" : "Sonata Software LTD",
            "description" : "Engineered a high-performance Java notification service, supporting up to 10k requests per second for the Order Management System. Successfully reduced Order Service latency by 70% through effective caching of critical order data using Redis and Java optimizations.",
            "skills" : ["React", "Java", "Redis","AWS Ec2", "Spring Boot", "SQl","JavaScript"]
        }
    ];

    const blogs = [
        {
            title: "How To Use Locking In Relational DB",
            date: "Jan 24, 2024",
            readTime: "2 min read",
            description: "Uncover How To Use Different Locking Techniques in PostgresSQL",
            link: "https://your-link-to-article"
        },
        {
            title: "Do You Know About Y2K Problem ?",
            date: "Nov 24, 2023",
            readTime: "2 min read",
            description: "Learn About Y2K and The Problems It Has Caused",
            link: "https://your-link-to-article"
        },
        // {
        //     title: "Cache Simplified: Your Device's Trusted Ally",
        //     date: "Oct 24, 2023",
        //     readTime: "2 min read",
        //     description: "Uncover How Cache Speeds Up Your Computer and Its Different Types and Quirks",
        //     link: "https://your-link-to-article"
        // },
        // {
        //     title: "Cache Simplified: Your Device's Trusted Ally",
        //     date: "Oct 24, 2023",
        //     readTime: "2 min read",
        //     description: "Uncover How Cache Speeds Up Your Computer and Its Different Types and Quirks",
        //     link: "https://your-link-to-article"
        // }
    ];

    const projects = [
        {
            title: "Let's Settle This",
            skills: ["Node.js", "React", "TypeScript", "Framer Motion"],
            link : ""
        },
        {
            title: "Look Around",
            skills: [ "React", "Maps API", "TypeScript"],
            link : ""
        },
        {
            title: "BjornDB",
            skills: ["Java"],
            path : "/letsSettleThis",
            link : "https://github.com/sriram-V20/BjornDB"
        },
        {
            title: "Distributed URL Shortener",
            skills: ["Locust", "React", "Node.js", "PostgresSQL"],
            link : ""
        }

    ];
    return (
        <div>
        <div className="hero-section" id = "home">
            <h1 className="heading">
                <span className="firstSpan">Hello. I'm Sriram.</span>
                <span className="secondSpan">A Passionate Engineer</span>
            </h1>
            <p className="thirdSpan"> I am a software engineer passionate about System Architecture, Language Internals, Distributed DataStores and Algorithms.</p>
        </div>
            <div className="about-section">
                <div style={{padding:"20px"}} id="about">
                    <p className="about-title">About</p>
                    <p className="about-content">I'm an ambitious software developer with a passion for problem-solving and innovation. I'm currently in San Francisco and My coding journey didn't start with a bang, but rather a curious click and a lot of "Oops...did I do that?" moments. My approach to coding? "If it doesn't work, reboot and Google." I'm not just about crafting code; I'm about creating laughs and lighting up lightbulb moments. Join me for a coding adventure where the journey is sprinkled with syntax errors, semicolons, and stories, proving that the biggest error is never trying at all.  </p>
                </div>
                <div className="skills-section" id="skill">
                    <h2>Skillset</h2>
                    <div className="skills-container">
                        {/*<span className="skill">React</span>*/}
                        {skills.map((skill,index)=>(
                            <span key = {index} className="skill" > {skill}</span>
                        ))}
                    </div>
                </div>
                <div className="experience-section" id="experience">
                    <h2 className="experience-title">Experience</h2>
                    {exps.map((exp, index) => (
                        <div className="experience-container" key={index}>
                            <div className="left-container">
                                <span className="experience-dates">{exp.startDate} - {exp.endDate}</span>
                            </div>
                            <div className="right-container">
                                <h3 className="experience-company">{exp.company} - {exp.role}</h3>
                                {/*<p className="experience-role"></p>*/}
                                <p className="experience-description">{exp.description}</p>
                                <div className="experience-skills">
                                    {exp.skills.map((skill, skillIndex) => (
                                        <span className="skill" key={skillIndex}>{skill}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="projects-section" id="work">
                    <h2 className="projects-title">Projects</h2>
                    <div className="projects-container">
                        {projects.map((project, index) => (
                            <div className="project-entry" key={index} >
                                <h3 className="project-name">{project.title}</h3>
                                {/*<a href="#" className="project-link"> <HiOutlineLink />*/}

                                {/*    <i className="fas fa-external-link-alt"/> /!* Font Awesome icon example *!/*/}
                                {/*</a>*/}
                                <a  className="project-link">
                                    {/* Icons or other elements */}
                                    <HiOutlineLink />
                                </a>
                                <div className="project-skills">
                                    {project.skills.map((skill, skillIndex) => (
                                        <span className="project-skill" key={skillIndex}>{skill}</span>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="blogs-section" id="writings">
                    <h2 className="blogs-title">Some of my writings</h2>
                    <div className="blogs-container">
                        {blogs.map((blog, index) => (
                            <div className="blog-entry" key={index}>
                                <h3 className="blog-title">{blog.title}</h3>
                                <p className="blog-date-readTime">{blog.date} - {blog.readTime}</p>
                                <p className="blog-description">{blog.description}</p>
                                <a href={blog.link} target="_blank" rel="noopener noreferrer" className="project-link">
                                    {/* Icons or other elements */}
                                    <HiOutlineLink />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="separator-line"></div>
                <footer className="footer" id = "contact">
                    <div className="footer-content">
                        <p>&copy; {2023} Sriram Tech</p>
                        <div className="footer-links">
                            <a href="https://www.linkedin.com/in/sriram-vemparala" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                            <a href="mailto:sriramvemparala27@gmail.com"><FaEnvelope /></a>
                            <a href="https://github.com/sriram-V20" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                            {/*<a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>*/}
                            {/*<a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>*/}
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default HeroSection;
