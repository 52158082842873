import React, {useEffect, useState} from 'react';
import './App.css';
import NavBar from "./Components/NavBar/NavBar";
import HeroSection from "./Components/HeroSection/HeroSection";
import AboutSection from "./Components/About/About";
import { Route, Routes, Router } from "react-router-dom"

const App = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true)
                document.body.classList.add('scrolled');
            } else {
                setIsScrolled(false)
                document.body.classList.remove('scrolled');
            }
            const scrollTop = window.scrollY;
            const maxScroll = window.innerHeight;
            const opacity = Math.max(1 - scrollTop / maxScroll, 0);
            document.body.style.setProperty('--scroll-opacity', opacity);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return (
      <div className="App">
          <NavBar isScrolled={isScrolled}/>
          {/*<Routes>*/}
          {/*    <Route path="/" element={}> </Route>*/}
          {/*  /!*<NavBar isScrolled={isScrolled}/>*!/*/}
          {/*        <Route path="/"><HeroSection /> </Route>*/}
          {/*        <Route path="/"><AboutSection /> </Route>*/}
          {/*  /!*<AboutSection />*!/*/}
          {/*</Routes>*/}
          <Routes>
              <Route path="/" element={<HeroSection />} />
              {/*<Route path="/" element={<AboutSection />} />*/}
              {/* Define other routes here */}
          </Routes>
      </div>
  );
};

export default App;
