import './NavBar.css'; // This is where you'll write your CSS

const NavBar = ({ isScrolled }) => {
    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="navbar-container">
                <ul className="nav-menu">
                    <li className="nav-item">
                        <a href="#home" className="nav-links">Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="#about" className="nav-links">About</a>
                    </li>
                    <li className="nav-item">
                        <a href="#work" className="nav-links">Work</a>
                    </li>
                    <li className="nav-item">
                        <a href="#writings" className="nav-links">Writings</a>
                    </li>
                    <li className="nav-item">
                        <a href="#contact" className="nav-links">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;
